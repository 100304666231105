import React, { useState, useEffect, useRef } from "react";
import "./CreateAssets.css";
import Headers from "Components/Header/Headers";
import Navigation from "Components/SideNavBar/Navigation";
import { useNavigate } from "react-router-dom";
import {
  createAsset,
  generateAssetId,
  getassetlocations,
} from "Config/API/action";
import {
  getGeneralInfo,
  getLocationData,
  getOrganizationConnection,
} from "Config/API/get";
import { toast } from "react-toastify";
import useFullPageLoader from "Hooks/useFullPageLoader";

const CreateAssets = () => {
  const navigate = useNavigate();
  const [loader, setLoader, resetLoader] = useFullPageLoader();
  const [assetName, setAssetName] = useState("");
  const [assetNumber, setAssetNumber] = useState("");
  const [description, setDescription] = useState("");
  const [location, setLocation] = useState("");
  const [vendorName, setVendorName] = useState("");
  const [organizations, setOrganizations] = useState([]);
  const [vendorNameid, setVendorNameid] = useState("");
  const [showDropdown, setShowDropdown] = useState(false);
  const [showLocations, setShowLocations] = useState(false); // Manage dropdown visibility
  const [assetTicket, setAssetticket] = useState("");
  const [assetLocation, setAssetLocation] = useState([]);
  const [assetLocationType, setAssetLocationType] = useState("");
  const token = localStorage.getItem("token");
  const [filteredAssetLocations, setFilteredAssetLocations] = useState("");
  const vendorRef = useRef(null);
  const [generalInfo, setGeneralInfo] = useState("");
  const [selectedLocationId, setSelectedLocationId] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  useOutsideAlerter(vendorRef);
  const dropdownRef = useRef(null);
  const assetScope = "internal";
  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setShowDropdown(false);
          // Hide dropdown when clicking outside
        } else {
          setShowDropdown(true);
        }
      }

      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowLocations(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  useEffect(() => {
    // const fetchData = async () => {
    //   const generateAssetResponse = await generateAssetId(token);
    //   if (generateAssetResponse?.status) {
    //     setAssetticket(generateAssetResponse?.data?.assetId);
    //   }
    // };

    // fetchData();

    getassetlocation();
  }, [token]);
  const getloginorg = async () => {
    const info = await getGeneralInfo(token);
    if (info?.status) {
      setGeneralInfo(info?.org_info);
    }
  };
  const getassetlocation = async () => {
    try {
      const info = await getGeneralInfo(token);
      const res = await getLocationData(token, info?.org_info?.id);

      if (res?.status === true) {
        // Check for the boolean value 'true'
        setAssetLocation(res.location);
        console.log(res.location); // Log the asset data directly
      } else {
        toast.error("No asset location found");
      }
    } catch (error) {
      toast.error(`Error fetching asset location: ${error.message}`);
      // Handle the error if needed (e.g., show a toast or alert)
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const trimmedAssetName = assetName.trim();
    const trimmedVendorName = vendorNameid;
    const trimmedAssetNumber = assetNumber.trim();
    const trimmedDescription = description.trim();
    const trimmedLocation = location;
    setIsSubmitting(true);
    if (
      trimmedAssetName !== "" &&
      trimmedVendorName !== "" &&
      trimmedDescription !== "" &&
      trimmedLocation !== "" &&
      trimmedAssetNumber.length <= 25
    ) {
      setLoader();

      try {
        const generateAssetResponse = await generateAssetId(token);
        if (generateAssetResponse?.status) {
          const assetId = generateAssetResponse?.data?.assetId;

          const assetData = {
            token: token,
            assetsName: trimmedAssetName,
            vendorName: trimmedVendorName,
            assetNumber: trimmedAssetNumber,
            description: trimmedDescription,
            assetId: assetId,
            assetLocation: trimmedLocation,
            assetScope: assetScope,
            assetLocationType: assetLocationType,
          };

          const assetResponse = await createAsset(assetData);
          resetLoader();

          if (assetResponse?.status) {
            toast(assetResponse?.message, { type: "success" });
            setIsSubmitting(false);
            navigate("/asset-management-view-page");
          } else {
            setIsSubmitting(false);
            toast(assetResponse?.message, { type: "error" });
          }
        } else {
          resetLoader();
          setIsSubmitting(false);
          toast("Failed to generate asset ID", { type: "error" });
        }
      } catch (error) {
        resetLoader();
        setIsSubmitting(false);
        console.error("Error during asset creation:", error);
        toast("An error occurred, please try again", { type: "error" });
      }
    } else {
      setIsSubmitting(false);
      if (trimmedAssetName === "") {
        toast("Please enter an asset name", { type: "info" });
      }
      if (trimmedVendorName === "") {
        toast("Please select a vendor name", { type: "info" });
      }
      if (trimmedDescription === "") {
        toast("Please enter a description", { type: "info" });
      }
      if (trimmedLocation === "") {
        toast("Please enter a location", { type: "info" });
      }
      if (trimmedAssetNumber.length > 25) {
        toast("Asset number must be 25 characters or fewer.", { type: "info" });
      }
    }
  };

  return (
    <>
      <div className="create-assets-parent">
        <div className="create-assets-header">
          <Headers />
        </div>
        <div className="create-assets-navigation">
          <Navigation />
        </div>
        <div className="create-assets-main">
          <div className="create-asset-heading">
            <h2>Create New Asset</h2>
          </div>
          <div className="create-assets-form">
            <form
              className="create-assets-form-body"
              onSubmit={handleSubmit}
              autoComplete="off"
            >
              {/* <div className="create-asset-fields">
                <label htmlFor="Asset-Name">
                  Ticket No.(auto generate)<span>*</span>:
                </label>
                <input
                  type="text"
                  id="Asset-Name"
                  required
                  readOnly
                  value={assetTicket}
                  placeholder="Ticket number for Asset"
                  style={{ color: "#446FA2", fontWeight: 700 }}
                />
              </div> */}
              <div className="create-asset-fields">
                <label htmlFor="Asset-Name">
                  Asset Name <span>*</span>:
                </label>
                <input
                  type="text"
                  id="Asset-Name"
                  maxLength={30}
                  required
                  value={assetName}
                  onChange={(e) => setAssetName(e.target.value)}
                  placeholder="Enter Name for Asset"
                />
              </div>
              <div className="create-asset-fields">
                <label htmlFor="Asset-Number">
                  Enter Asset # <span>*</span>:
                </label>
                <input
                  type="text"
                  id="Asset-Number"
                  required
                  value={assetNumber}
                  onChange={(e) =>
                    setAssetNumber(e.target.value.trimStart().slice(0, 25))
                  }
                  placeholder="Enter Serial Number or Asset Number"
                  maxLength={30}
                />
              </div>
              <div className="create-asset-fields">
                <label htmlFor="Asset-Description">
                  Enter Description<span>*</span>:
                </label>
                <textarea
                  id="Asset-Description"
                  maxLength={200}
                  rows={4}
                  required
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  placeholder="Enter Description of Asset"
                ></textarea>
              </div>
              <div className="create-asset-fields">
                <label htmlFor="Asset-Location">
                  Asset Location <span>*</span>:
                </label>
                <div style={{ position: "relative" }} ref={dropdownRef}>
                  <input
                    type="text"
                    maxLength={30}
                    id="Asset-Location"
                    required
                    value={selectedLocationId}
                    onClick={() => {
                      getassetlocation();
                      setShowLocations(true);
                    }}
                    onChange={(e) => {
                      const locationValue = e.target.value.trimStart();
                      setLocation(locationValue);
                      setSelectedLocationId(locationValue);
                      setShowLocations(true);
                      setAssetLocationType("text");

                      if (assetLocation) {
                        if (locationValue.length > 0) {
                          const filteredLocations = assetLocation.filter(
                            (loc) =>
                              loc.location
                                .toLowerCase()
                                .includes(locationValue.toLowerCase())
                          );
                          setFilteredAssetLocations(filteredLocations);

                          // Automatically select if input matches an existing location
                          const exactMatch = filteredLocations.find(
                            (loc) =>
                              loc.location.toLowerCase() ===
                              locationValue.toLowerCase()
                          );
                          if (exactMatch) {
                            setLocation(exactMatch.id);
                            setSelectedLocationId(exactMatch.location);
                            setShowLocations(false);
                            setAssetLocationType("id");
                          }
                        } else {
                          setFilteredAssetLocations(assetLocation); // Show all locations when input is empty
                        }
                      }
                    }}
                    placeholder="Company Location for Asset"
                  />
                  {showLocations && filteredAssetLocations?.length > 0 && (
                    <div className="location-dropdown">
                      <ul>
                        {filteredAssetLocations.map((loc) => (
                          <li
                            key={loc.id}
                            onClick={() => {
                              setLocation(loc.id);
                              setSelectedLocationId(loc.location);
                              setShowLocations(false);
                              setAssetLocationType("id");
                            }}
                            className="dropdown-option"
                          >
                            {loc.location}
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                </div>
              </div>

              <div className="create-asset-fields vendor-name-field">
                <label htmlFor="Asset-Vendor">
                  Vendor Name <span>*</span>:
                </label>

                <div ref={vendorRef} style={{ position: "relative" }}>
                  <input
                    type="search"
                    id="Asset-Vendor"
                    maxLength={30}
                    style={{
                      backgroundColor:
                        vendorName.length > 0 ? "#f1f2f6" : "white",
                    }}
                    value={vendorName}
                    placeholder="Search Vendor"
                    onClick={() => {
                      // Do nothing if vendorName has content
                      if (vendorName.length > 0) return;

                      // Fetch organization list
                      getloginorg();
                    }}
                    onChange={(e) => {
                      const vendorValue = e.target.value;
                      setVendorName(vendorValue.trimStart());
                      setVendorNameid(vendorValue.trimStart());

                      // If input length is greater than 0, perform filtering or fetch data
                      if (vendorValue.length > 0) {
                        // Fetch organizations based on the vendor input
                        const vendor_status = getOrganizationConnection(
                          token,
                          vendorValue
                        );
                        vendor_status.then((res) => {
                          if (res?.status) {
                            // Filter the organizations from the API result
                            const filteredOrganizations =
                              res.organizationData.filter((org) =>
                                org.organization_name
                                  .toLowerCase()
                                  .includes(vendorValue.toLowerCase())
                              );

                            // Check if `generalInfo` matches the search and add it to the filtered list (only if it's not already in the list)
                            const filteredGeneralInfo =
                              generalInfo &&
                              generalInfo.organization_name
                                .toLowerCase()
                                .includes(vendorValue.toLowerCase()) &&
                              !filteredOrganizations.some(
                                (org) => org.id === generalInfo.id
                              ) // Ensure it isn't already in the filtered list
                                ? [generalInfo]
                                : [];

                            // Update state with filtered organizations and generalInfo
                            setOrganizations([
                              ...filteredOrganizations,
                              ...filteredGeneralInfo,
                            ]);

                            // Show the dropdown only if there are filtered organizations
                            setShowDropdown(
                              filteredOrganizations.length > 0 ||
                                filteredGeneralInfo.length > 0
                            );
                          } else {
                            toast(res?.message, { type: "error" });
                            setShowDropdown(false);
                            setOrganizations([]); // Clear organizations if there's an error
                          }
                        });
                      } else {
                        // If input is cleared (empty), immediately hide the dropdown and clear organizations list
                        setShowDropdown(false);
                        setOrganizations([]);
                      }
                    }}
                  />

                  {showDropdown && organizations.length > 0 && (
                    <div className="organization-list folder-list">
                      {organizations.map((vendor, index) => (
                        <div
                          className="data-list"
                          key={vendor.id || index} // Use `vendor.id` if available, otherwise `index`
                          onClick={() => {
                            setVendorName(vendor.organization_name);
                            setVendorNameid(vendor.id);
                            setOrganizations([]);
                            setShowDropdown(false);
                          }}
                        >
                          <div>
                            <div className="organization-img-parent">
                              <img src={vendor.logo} alt="Vendor" />
                            </div>
                            <span
                              className="multiple-line-text"
                              style={{ color: "#51595E" }}
                            >
                              {vendor.organization_name || "No Name"}
                            </span>
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </div>

              <div className="submitbutton">
                <button type="submit" disabled={isSubmitting}>
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateAssets;
