import React, { useEffect, useState, useRef } from "react";
import "./AssetDetails.css";
import Headers from "Components/Header/Headers";
import Navigation from "Components/SideNavBar/Navigation";
import { useNavigate } from "react-router-dom";
import Modal from "@mui/material/Modal";
import { IconButton } from "@mui/material";
import bFolder from "Media/icons/Folder-Close.png";
import Low from "Media/icons/Low.png";
import Moderate from "Media/icons/Moderate.png";
import Pause from "Media/icons/Pause.png";
import Urgent from "Media/icons/Urgent.png";
import { useLocation } from "react-router-dom";
import {
  getAssetByUniqueID,
  getAssetServiceTicket,
  getOrganizationConnection,
  getuploadedfiles,
  getAssetPart,
  createAssetFolder,
  getAssetFolders,
  getUserPermissions,
  getUserDetails,
  archiveAssetFolder,
  renameAssetFolder,
  getGeneralInfo,
  getLocationData,
  listOfAppointOrg,
} from "Config/API/get";
import { addAssetFile } from "Config/API/action";
import useIsStorageLimitReached from "Hooks/useIsStorageLimitReached";
import useFullPageLoader from "Hooks/useFullPageLoader"; // Loader component
import qrcode from "Media/icons/metro-qrcode.svg";
import shareIcon from "Media/icons/material-share.svg";
import { toast } from "react-toastify";
import uploadIcon from "Media/icons/awesome-file-upload.svg";
import folderSettings from "Media/icons/ionic-ios-settings.svg";
import { useDispatch, useSelector } from "react-redux";
import CreateIcon from "@mui/icons-material/Create";
import { Button } from "@mui/material";
import { updateAsset } from "Config/API/update";
import demo from "Media/blank-profile-picture.png";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import {
  assetuniqueid,
  ticketIdAction,
  assethashid,
} from "Redux/action/variable.action";
import AssetQrPopup from "Components/Popup/AssetQRPopup/assetQrPopup.jsx";
import options from "Media/icons/feather-more-vertical.svg";
import ShareAssetPopup from "Components/Popup/ShareAssetPopup/ShareAssetPopup";
import { renderOrganizationInfo } from "Redux/action/render.action";
import { getFileIcon } from "Constants/Functions";
import ServiceRequestForm from "pages/Assets/ServiceRequestForm/ServiceRequestForm";
import RenderConfirm from "Components/Popup/Confirm/Container/RenderConfirm";
import webLinkImage from "Media/icons/webLinkImage.png";
import IFramePopup from "Components/Popup/IFramePopup/IFramePopup";
const AssetDetails = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [myAsset, setMyAsset] = useState(null);
  const [loader, setLoader, resetLoader] = useFullPageLoader();
  const token = localStorage.getItem("token");
  const isStorageLimitReached = useIsStorageLimitReached();
  const pageType = useSelector((state) => state.servicePageTypeReducer);
  const [userData, setUserData] = useState("");
  const [generalInfo, setGeneralInfo] = useState("");
  const [assetUserRole, setAssetUserRole] = useState("");
  const [notAccessable, setNotAccessable] = useState("");

  const [internalRequestPopup, setInternalRequestPopup] = useState(false);
  let doneTypingInterval = 300;
  const [qrPopup, setQrPopup] = useState(false);
  const [shareAssetPopup, setShareAssetPopup] = useState(false);
  const [vendorName, setVendorName] = useState("");
  const vendorRef = useRef(null);
  const locationRef = useRef(null);
  const [vendorNameId, setVendorNameid] = useState("");
  const [showDropdown, setShowDropdown] = useState(false);
  const [showLocation, setShowLocation] = useState(false);
  const [folderName, setFolderName] = useState("");
  const [organizations, setOrganizations] = useState([]);
  useOutsideAlerter(vendorRef);
  const [usersAllPermissions, setUsersAllPermissions] = useState([]);
  const [inputModal, setInputModal] = useState("");
  const [currentFolder, setCurrentFolder] = useState(null);
  const uniqueid = useSelector((state) => state.assetIdReducer);

  const [filterKeyword, setFilterKeyword] = useState("all");
  const [searchKeyword, setSearchKeyword] = useState("");
  const [partsearchKeyword, setpartSearchKeyword] = useState("");
  const [activeSection, setActiveSection] = useState("serviceRequests");
  // const [uploadedFiles, setUploadedFiles] = useState(null);
  const [assetFolders, setAssetFolders] = useState(null);
  const [assetServiceTicket, setAssetServiceTicket] = useState(null);
  const [assetPart, setAssetPart] = useState(null);
  const [filteredPart, setFilteredPart] = useState(null);
  const [isEditable, setIsEditable] = useState(false);
  const [editedAsset, setEditedAsset] = useState({});
  const [isVendorSelected, setIsVendorSelected] = useState(false);
  const [dropdownOpenId, setDropdownOpenId] = useState(null);
  const [showStorageLimitReachedPopup, setShowStorageLimitReachedPopup] =
    useState(false);
  const [assetLocation, setAssetLocation] = useState([]);
  const [uploadButtonDisable, setUploadButtonDisable] = useState(false);
  const [locationName, setLocationName] = useState();
  const [folderPath, setFolderPath] = useState(["0"]);
  const path = "";
  const [parentUniqueId, setParentUniqueId] = useState("0");
  const [bookmark, setBookmark] = useState(null);
  const [isModalOpen, setModalOpen] = useState(false);
  const [typingTimer, setTypingTimer] = useState(null);
  const [showIFramePopup, setShowIFramePopup] = useState(false);

  const location = useLocation();
  const fullURL = window.location.href;
  const parsedURL = new URL(fullURL);
  const dropdownRef = useRef({});
  const [assetLocationType, setAssetLocationType] = useState("");
  const [confirmPopup, setConfirmPopup] = useState(false);
  const baseURL = parsedURL.protocol + "//" + parsedURL.hostname;
  const { isShared } =
    { isShared: notAccessable === "shared" ? true : false } || {};

  const { partdetail } = location.state || {};

  const [url, setUrl] = useState("");
  const [isFolder, setIsFolder] = useState(false);
  const [filteredAssetLocations, setFilteredAssetLocations] = useState("");
  const [error, setError] = useState("");
  const inputRef = useRef(null);
  const urlRegex = /^(https?:\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,6}\/?/;
  // useEffect(()=>{
  //   if(partdetail&&partdetail===true)
  // {
  //   setActiveSection("partsManagement")
  // }},[partdetail])
  // console.log(activeSection)
  useEffect(() => {
    const fetchData = async () => {
      await getAssetDetails(); // Run this first and wait for it to complete
      await getAssetParts(); // Then run this
      await getuserData(); // Then run this
      await getAssetServiceTickets(); // Finally run this
    };

    fetchData(); // Call the fetchData function
  }, []); // Empty dependency array ensures this runs once when the component mounts

  const getuserData = () => {
    const apiResponse = getUserDetails(token);

    apiResponse.then((res) => {
      setUserData(res.data.data.ticket_external_role);
      setAssetUserRole(res.data.data.asset_manager);
    });
  };
  const qrCodeUrl = myAsset?.sharePath
    ?.toString()
    ?.replace(
      baseURL,
      `${baseURL}/amnius-connect/api/deep-link?url=amniusconnect://app`
    );
  // Function to create a new weblink

  const handleOpenModal = () => {
    setModalOpen(true);
    // getbrowsefiles();
    setParentUniqueId("0");
    getAssetFoldersData();
  };
  const [selectedPath, setSelectedPath] = useState("");

  const handleFolderClick = (name) => {
    setSelectedPath((prev) => (prev ? `${prev} > ${name}` : `>${name}`));
  };
  const handleDropdownClick = (id) => {
    setDropdownOpenId(dropdownOpenId === id ? null : id);
  };

  const handleOpen = (curr) => {
    if (curr.type === "folder") {
      setParentUniqueId(curr.unique_id);
      setFolderPath((prevItems) => [...prevItems, curr.unique_id]);
    } else {
      localStorage.setItem("filePath", curr?.path);
      localStorage.setItem(
        "fileType",
        curr?.name?.split(".").pop()?.toLowerCase()
      );
      window.open(
        `${window.location.origin}/file-explorer/file-viewer`,
        "_blank"
      );
    }
    setDropdownOpenId(null);
  };

  const handleDownload = async (curr) => {
    if (curr.type === "file") {
      const fileName = curr.name;

      try {
        // Fetch the file as a blob
        const response = await fetch(curr.path);

        if (!response.ok) {
          throw new Error(`Failed to fetch file: ${response.statusText}`);
        }

        const blob = await response.blob();

        // Create a temporary download link
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.style.display = "none";
        link.href = url;
        link.download = fileName;
        document.body.appendChild(link);
        link.click();

        // Cleanup
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);

        // Success toast
        toast("Downloading completed", { type: "success" });
      } catch (error) {
        console.error("Error downloading the file:", error);

        // Error toast
        toast("Downloading failed", { type: "error" });
      }
    }
  };

  const handleRename = async () => {
    setLoader();
    setIsFolder(true);
    try {
      const response = await renameAssetFolder(
        token,
        currentFolder?.unique_id,
        folderName,
        folderType,
        url
      );
      if (response && response.status) {
        getAssetFoldersData();
        setInputModal("");
        setFolderName("");
        setUrl("");
        setIsFolder(false);
        toast(response.message, { type: "success" });
      } else {
        toast(response.message, { type: "error" });
        setIsFolder(false);
      }
    } catch (error) {
      toast("Failed! Try again later", { type: "error" });
      setIsFolder(false);
    } finally {
      resetLoader();
    }
  };

  const handleDelete = async (curr) => {
    setLoader();
    try {
      const response = await archiveAssetFolder(token, curr?.unique_id);
      if (response && response.status) {
        getAssetFoldersData();
        toast(response.message, { type: "success" });
      } else {
        toast(response.message, { type: "error" });
      }
    } catch (error) {
      toast("Failed! Try again later", { type: "error" });
    } finally {
      resetLoader();
      setDropdownOpenId(null);
    }
  };

  const handleOutsideClick = (event) => {
    const isOutside = Object.values(dropdownRef.current).every(
      (ref) => ref && !ref.contains(event.target)
    );
    if (isOutside) {
      setDropdownOpenId(null);
    }
    if (locationRef.current && !locationRef.current.contains(event.target)) {
      setShowLocation(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [dropdownOpenId, showLocation]);

  useEffect(() => {
    const usersAllPermissions = getUserPermissions(token);

    usersAllPermissions.then((res) => {
      if (res?.status) {
        setUsersAllPermissions(res?.data?.emp);
      }
    });

    getloginorg();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    // getbrowsefiles();
    getAssetFoldersData();
  }, [parentUniqueId]);

  const handleCloseModal = () => {
    setModalOpen(false);
    setParentUniqueId("0");
    setDropdownOpenId(null);
  };
  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setShowDropdown(false); // Hide dropdown when clicking outside
        } else {
          setShowDropdown(true); // Show dropdown if clicking inside
        }
      }

      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  const addAFile = async (uploadFile) => {
    setUploadButtonDisable(true);
    const toastId = toast("Uploading, Please Wait...", { type: "info" });
    try {
      const res = await addAssetFile(
        token,
        myAsset.unique_id,
        path,
        parentUniqueId,
        uploadFile,
        { current: toastId }
      );

      if (res?.status) {
        if (res?.message?.includes("limit")) {
        }
        toast.update(toastId, {
          render: "Uploaded!",
          type: "success",
          autoClose: 4000,
        });

        getAssetFoldersData();
      } else {
        toast(res?.message, { type: "error" });
      }
    } catch (error) {
      toast("Failed! Try again later", { type: "error" });
    } finally {
      setUploadButtonDisable(false);
    }
  };
  let sharingstatus;
  const getAssetDetails = async () => {
    setLoader();
    try {
      const assetData = await getAssetByUniqueID(token, uniqueid);
      if (assetData && assetData.status) {
        // Check if status is true
        sharingstatus = assetData.data.data.qrCode;
        setMyAsset(assetData.data.data);
        setEditedAsset(assetData.data.data);
        setLocationName(assetData.data.data.assetLocation);
        setNotAccessable(assetData.data.data.qrCode);
      } else {
        toast("Failed! Try again later", { type: "error" });
      }
    } catch (error) {
      toast("Failed! Try again later", { type: "error" });
    } finally {
      resetLoader();
    }
  };
  const [folderType, setFolderType] = useState("");
  const createNewAssetFolder = async () => {
    setLoader();
    setIsFolder(true);
    try {
      // Check if a folder with the same name already exists
      if (
        Array.isArray(assetFolders) &&
        assetFolders.some((folder) => folder.name === folderName)
      ) {
        toast("Folder name already exists", { type: "error" });
        resetLoader();
        return;
      }

      const assetData = await createAssetFolder(
        token,
        myAsset?.unique_id,
        folderName,

        parentUniqueId,
        folderType,
        url
      );

      if (assetData && assetData.status) {
        // If folder creation is successful
        toast("Asset Folder created successfully", { type: "success" });
        getAssetFoldersData();
        setInputModal("");
        setFolderName("");
        setFolderType("");
        setIsFolder(false);
        setUrl("");
      } else {
        toast("Failed! Try again later", { type: "error" });
        setFolderName("");
        setFolderType("");
        setIsFolder(false);
        setUrl("");
      }
    } catch (error) {
      toast("Failed! Try again later", { type: "error" });
      setFolderName("");
      setFolderType("");
      setIsFolder(false);
      setUrl("");
    } finally {
      resetLoader();
    }
  };

  const getAssetFoldersData = async () => {
    setLoader();
    try {
      const folders = await getAssetFolders(token, uniqueid, parentUniqueId);

      // Ensure the response is structured as expected
      if (folders && folders.status && folders.data && folders.data.data) {
        // setAssetFolders(folders.data.data);

        const sortedFolders = folders.data.data.sort((a, b) => {
          const dateA = new Date(a.createdAt);
          const dateB = new Date(b.createdAt);

          return dateB - dateA; // Sort in descending order (latest first)
        });

        // Set the sorted folders
        setAssetFolders(sortedFolders);

        setBookmark(folders.data.bookmarkData);
      } else {
        toast("Failed to fetch folders! Try again later", { type: "error" });
      }
    } catch (error) {
      console.error("Error fetching folders:", error);
      toast("Failed to fetch folders! Try again later", { type: "error" });
    } finally {
      resetLoader();
    }
  };

  const getAssetParts = async () => {
    setLoader();
    try {
      const assetData = await getAssetPart(token, uniqueid);

      if (assetData && assetData.status) {
        setAssetPart(assetData.data.data);
        setFilteredPart(assetData.data.data);
      } else {
        toast("Failed! Try again later", { type: "error" });
      }
    } catch (error) {
      toast("Failed! Try again later", { type: "error" });
    } finally {
      resetLoader();
    }
  };
 const [filtering ,setFiltering]=useState("")
 useEffect(() => {
  // Run only if filtering is not empty
  if (filtering !== "") {
    getAssetServiceTickets();
  }
}, [filtering]);
  useEffect(() => {
    const handleTyping = () => {
      clearTimeout(typingTimer);
      const timer = setTimeout(() => {
        const filtered = assetPart?.filter((part) =>
          part.partHash.toLowerCase().includes(partsearchKeyword.toLowerCase())
        );

        setFilteredPart(filtered);
      }, doneTypingInterval);
      setTypingTimer(timer);
    };

    handleTyping();

    // Cleanup on unmount
    return () => {
      clearTimeout(typingTimer);
    };
  }, [partsearchKeyword]);
  const handleEditClick = () => {
    setIsEditable(true);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditedAsset((prev) => ({
      ...prev,
      [name]: value.trimStart(),
    }));
  };
  const [selectedAssetId, setSelectedAssetId] = useState(null);
  const handleSubmit = async (e) => {
    e.preventDefault();
    const trimmedAssetName = editedAsset.assetsName?.trim();
    const trimmedAssetNumber = editedAsset.assetNumber?.toString();
    const trimmedDescription = editedAsset.description?.trim();
    const trimmedLocation = editedAsset.assetLocation;
    const trimmedVendorName = isVendorSelected
      ? vendorNameId
      : editedAsset.vendorName.trim();
    if (
      trimmedAssetName !== "" &&
      trimmedVendorName !== "" &&
      trimmedDescription !== "" &&
      trimmedLocation !== "" &&
      trimmedAssetNumber !== ""
    ) {
      setLoader();
      const assetData = {
        token: token,
        unique_id: uniqueid,
        updates: {
          assetsName: trimmedAssetName,
          vendorName: trimmedVendorName,
          assetNumber: trimmedAssetNumber,
          description: trimmedDescription,
          assetLocation: trimmedLocation,
          assetLocationType: assetLocationType,
        },
      };
      try {
        const assetResponse = await updateAsset(assetData);
        resetLoader();

        if (assetResponse?.status) {
          toast("Asset Updated Successfully", { type: "success" });
          setIsEditable(false);
          getAssetDetails();
        } else {
          toast(assetResponse?.message, { type: "error" });
        }
      } catch (error) {
        resetLoader();
        toast("An error occurred, please try again", { type: "error" });
      }
    } else {
      if (trimmedAssetName === "")
        toast("Please enter an asset name", { type: "info" });
      if (trimmedVendorName === "")
        toast("Please select a vendor name", { type: "info" });
      if (trimmedDescription === "")
        toast("Please enter a description", { type: "info" });
      if (trimmedLocation === "")
        toast("Please enter a location", { type: "info" });
      if (trimmedAssetNumber === "")
        toast("Please enter a asset #", { type: "info" });
    }
  };
  const handleClose = () => {
    getAssetDetails();
    setIsEditable(false);
  };

  // const handleGoBack = () => {
  //   if (folderPath[folderPath.length - 1] !== "0") {
  //     setParentUniqueId(folderPath[folderPath.length - 2]);
  //     setFolderPath((prevItems) => prevItems.slice(0, prevItems.length - 1));
  //   }
  // };
  const fetchAndSetOrganizations = async (token, value) => {
    const vendorStatus = await getOrganizationConnection(token, value);

    if (vendorStatus?.status) {
      setOrganizations(vendorStatus.organizationData);
      setShowDropdown(true);
    } else {
      toast(vendorStatus?.message || "Error fetching organizations", {
        type: "error",
      });
    }
  };
  const getloginorg = async () => {
    const info = await getGeneralInfo(token);
    if (info?.status) {
      setGeneralInfo(info?.org_info);
    }
  };
  const getassetlocation = async () => {
    try {
      const info = await getGeneralInfo(token);
      const res = await getLocationData(token, info?.org_info?.id);

      if (res?.status === true) {
        // Check for the boolean value 'true'
        setAssetLocation(res.location);
      } else {
        toast.error("No asset location found");
      }
    } catch (error) {
      toast.error(`Error fetching asset location: ${error.message}`);
      // Handle the error if needed (e.g., show a toast or alert)
    }
  };
  useEffect(() => {
    getassetlocation();
  }, [token]);

  const handleChange = (e) => {
    const newUrl = e.target.value;
    setUrl(newUrl);

    // Reset error when the user types a new value
    setError("");
  };

  // Validate URL when input loses focus
  const handleBlururl = () => {
    if (url && !urlRegex.test(url)) {
      setError("Invalid URL format");
    }
  };
  useEffect(() => {
    const handleClickOutside = (event) => {
      // Guard against undefined references
      const isClickInsideDropdown = Object.values(dropdownRef.current).some(
        (dropdown) => dropdown && dropdown.contains(event.target) // Check for null dropdown
      );
      const isClickInsideButton = event.target.closest(
        ".dropdown-icon-container"
      );

      if (!isClickInsideDropdown && !isClickInsideButton) {
        setDropdownOpenId(null); // Close the dropdown if the click is outside
      }
    };

    // Add event listener for clicks outside
    document.addEventListener("click", handleClickOutside);

    // Cleanup the event listener on component unmount
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    if (notAccessable === "noAccessable") {
      setShowPopup(true);
      setTimeout(() => {
        navigate("/asset-management-view-page");
      }, 3000);
    }
  }, [notAccessable, navigate]);
  const getAssetServiceTickets = async () => {
    setLoader();

    try {
      const assetData = await getAssetServiceTicket(
        token,
        uniqueid,
        searchKeyword,
        filterKeyword,
        sharingstatus?sharingstatus:myAsset.qrCode
      );

      if (assetData && assetData.status) {
       
        setAssetServiceTicket(assetData.data.data);
      } else {
        toast("Failed! Try again later", { type: "error" });
      }
    } catch (error) {
      toast("Failed! Try again later", { type: "error" });
    } finally {
      resetLoader();
    }
  };
  return (
    <>
      <div className="asset-details-parent">
        <div className="asset-details-header">
          <Headers />
        </div>
        <div className="asset-details-navigation">
          <Navigation />
        </div>
        <div className="asset-details-content">
          <div className="asset-info-section">
            {/* Asset Details */}
            <div className="my-asset-details">
              <div className="asset-details-left-header">
                <div className="edit-part">
                  {!isShared ? <h2>My Asset</h2> : <h2>Shared Asset</h2>}
                  {!isShared &&
                    assetUserRole &&
                    (!isEditable ? (
                      <button className="edit-button" onClick={handleEditClick}>
                        <CreateIcon /> &nbsp;Edit
                      </button>
                    ) : (
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <button
                          className="header-one-btn-one"
                          onClick={handleSubmit}
                          style={{ marginLeft: "10px", padding: "8px" }}
                        >
                          Submit
                        </button>
                        <IconButton onClick={() => handleClose()}>
                          <CloseIcon />
                        </IconButton>
                      </div>
                    ))}
                </div>
                {/* {(isShared || assetUserRole === "") && ( */}
                  <button
                    className="service-request"
                    onClick={() => {
                      dispatch(assetuniqueid(uniqueid));
                      dispatch(assethashid(editedAsset.assetNumber));
                      setInternalRequestPopup(true);
                    }}
                  >
                    SERVICE REQUEST
                  </button>
                {/* )} */}
                
              </div>
              {myAsset ? (
                <div className="asset-info">
                  <div className="asset-detail-field">
                    <strong>Asset Owner:</strong>
                    <div className="asset-owner">
                      <input
                        type="text"
                        name="organization_name"
                        value={myAsset?.organizationDetail?.organization_name}
                        readOnly
                        onChange={handleInputChange}
                        style={{ marginRight: "12px", width: "100%" }}
                      />

                      <div className="visit-icon">
                        <img
                          src={(myAsset?.organizationDetail?.logo) ? (myAsset?.organizationDetail?.logo):demo}
                          alt="Logo"
                        />
                        <Button
                          variant="text"
                          className="visit-page-cta"
                          onClick={() => {
                            navigate("/organization", {
                              state: {
                                data:
                                  myAsset && myAsset?.qrCode === "shared"
                                    ? myAsset?.createdBy
                                    : myAsset?.org_id,
                                ...(myAsset &&
                                  myAsset?.qrCode !== "shared" && {
                                    from: "navigation",
                                  }),
                              },
                            });
                            dispatch(renderOrganizationInfo({}));
                          }}
                        >
                          VISIT PAGE
                        </Button>
                      </div>
                    </div>
                  </div>

                  <div className="asset-detail-field">
                    <strong>
                      Asset Name<span className="red"> *</span>:
                    </strong>
                    <input
                      type="text"
                      name="assetsName"
                      value={editedAsset?.assetsName}
                      readOnly={!isEditable}
                      onChange={handleInputChange}
                      maxLength={30}
                    />
                  </div>

                  <div className="asset-detail-field">
                    <strong>
                      Asset #<span className="red">*</span>:
                    </strong>
                    <input
                      type="text"
                      name="assetNumber"
                      value={editedAsset?.assetNumber}
                      readOnly={!isEditable}
                      onChange={handleInputChange}
                      maxLength={30}
                    />
                  </div>

                  <div className="asset-detail-field">
                    <strong>Description:</strong>
                    <textarea
                      name="description"
                      value={editedAsset?.description}
                      readOnly={!isEditable}
                      onChange={handleInputChange}
                      rows="4"
                      style={{ resize: "none" }}
                      maxLength={250}
                    />
                  </div>

                  <div className="asset-detail-field">
                    <strong>Asset Location:</strong>
                    <div ref={locationRef} className="vendorname">
                      <input
                        type="text"
                        name="assetLocation"
                        value={locationName}
                        placeholder="Location"
                        readOnly={!isEditable}
                        maxLength={30}
                        onChange={(e) => {
                          const locationValue = e.target.value;
                          const inputValue = locationValue
                            .toLowerCase()
                            .trimStart();

                          setEditedAsset((prev) => ({
                            ...prev,
                            assetLocation: inputValue,
                          }));
                          setAssetLocationType("text");
                          setLocationName(inputValue);

                          if (assetLocation) {
                            if (locationValue && locationValue.length > 0) {
                              const filteredLocations = assetLocation.filter(
                                (loc) =>
                                  loc.location
                                    .toLowerCase()
                                    .includes(inputValue)
                              );
                              setFilteredAssetLocations(filteredLocations);
                              setShowLocation(true);

                              // Automatically select if input matches an existing location
                              const exactMatch = filteredLocations.find(
                                (loc) =>
                                  loc?.location?.toLowerCase() === inputValue
                              );
                              if (exactMatch) {
                                setEditedAsset((prev) => ({
                                  ...prev,
                                  assetLocation: exactMatch?.id,
                                }));
                                setAssetLocationType("id");
                                setLocationName(exactMatch?.location);
                                setShowLocation(false);
                              }
                            } else {
                              setShowLocation(false);
                            }
                          }
                        }}
                        onClick={() => {
                          getassetlocation(); // Ensure this fetches all locations
                          setFilteredAssetLocations();
                        }}
                      />

                      {showLocation && isEditable && (
                        <div
                          className="organization-list asset-list"
                          style={{ zIndex: "10" }}
                        >
                          {filteredAssetLocations &&
                            filteredAssetLocations?.length > 0 &&
                            filteredAssetLocations?.map((loc) => (
                              <div
                                className="data-list"
                                key={loc?.id}
                                onClick={() => {
                                  setEditedAsset((prev) => ({
                                    ...prev,
                                    assetLocation: loc?.id,
                                  }));
                                  setAssetLocationType("id");
                                  setLocationName(loc?.location);
                                  setShowLocation(false);
                                }}
                                style={{ cursor: "pointer" }}
                              >
                                <span className="multiple-line-text">
                                  {loc?.location || "No Location"}
                                </span>
                              </div>
                            ))}
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="asset-detail-field">
                    <strong>Vendor Name:</strong>
                    <div ref={vendorRef} className="vendorname">
                      <input
                        type="text"
                        name="vendorName"
                        value={editedAsset?.vendorName}
                        placeholder="Vendor Name"
                        readOnly={!isEditable}
                        maxLength={30}
                        onChange={(e) => {
                          const inputValue = e.target.value.toLowerCase();
                          handleInputChange(e);
                          setIsVendorSelected(false);
                          if (inputValue?.length > 0) {
                            if (!editedAsset?.vendorName?.length) {
                              fetchAndSetOrganizations(token, ""); // Show all organizations initially
                            }
                            const filteredOrganizations = organizations.filter(
                              (vendor) =>
                                vendor?.organization_name
                                  .toLowerCase()
                                  .includes(inputValue)
                            );
                            const filteredGeneralInfo =
                              generalInfo &&
                              generalInfo?.organization_name
                                .toLowerCase()
                                .includes(inputValue.toLowerCase()) &&
                              !filteredOrganizations.some(
                                (org) => org?.id === generalInfo?.id
                              ) // Ensure it isn't already in the filtered list
                                ? [generalInfo]
                                : [];

                            // Update state with filtered organizations and generalInfo
                            setOrganizations([
                              ...filteredOrganizations,
                              ...filteredGeneralInfo,
                            ]);

                            // Show the dropdown only if there are filtered organizations
                            setShowDropdown(
                              filteredOrganizations.length > 0 ||
                                filteredGeneralInfo.length > 0
                            );
                          } else {
                            setShowDropdown(false);
                            setOrganizations([]);
                          }
                        }}
                        onClick={() => {
                          setShowDropdown(false);
                        }}
                      />

                      {showDropdown && isEditable && (
                        <div className="organization-list asset-list">
                          {organizations.map((vendor, index) => (
                            <div
                              className="data-list"
                              key={vendor.id || index} // Use `vendor.id` if available, otherwise `index`
                              onClick={() => {
                                setEditedAsset((prev) => ({
                                  ...prev,
                                  vendorName: vendor?.organization_name, // Update the selected organization's name
                                }));
                                setVendorNameid(vendor?.id); // Optional: Update the vendor ID
                                setOrganizations([]); // Clear dropdown
                                setShowDropdown(false); // Close dropdown
                              }}
                            >
                              <div>
                                <div className="organization-img-parent">
                                  <img src={vendor?.logo} alt="Vendor" />
                                </div>
                                <span
                                  className="multiple-line-text"
                                  style={{ color: "#51595E" }}
                                >
                                  {vendor?.organization_name || "No Name"}
                                </span>
                              </div>
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              ) : (
                <div className="asset-detail-field">No assets available.</div>
              )}

              {/* Buttons */}
              <div className="asset-buttons">
                <button
                  className="asset-button"
                  style={{ borderRadius: "0 0 0 10px" }}
                  onClick={() => setQrPopup(true)}
                >
                  <img src={qrcode} width="21px" alt="Add Folder" />
                  <p>Create QR Code</p>
                </button>
                {qrPopup ? (
                  <>
                    <AssetQrPopup
                      orglogo={myAsset?.organizationDetail?.logo}
                      assetName={myAsset?.assetsName}
                      // internalFolder={internalFolder}
                      hide={setQrPopup}
                      path={qrCodeUrl}
                      demologo={(myAsset?.organizationDetail?.logo)?(myAsset?.organizationDetail?.logo):demo}
                    />
                  </>
                ) : null}
                {!isShared && assetUserRole && (
                  <button
                    className="asset-button"
                    onClick={() => setShareAssetPopup(true)}
                  >
                    <img src={shareIcon} width="21px" alt="Add Folder" />
                    <p>Share Asset</p>
                  </button>
                )}
                {shareAssetPopup ? (
                  <>
                    <ShareAssetPopup
                      hide={setShareAssetPopup}
                      assetId={uniqueid}
                    />
                  </>
                ) : null}
                <button className="asset-button" onClick={handleOpenModal}>
                  <img src={uploadIcon} width="21px" alt="Add Folder" />
                  <p>Browse Files</p>
                </button>

                <Modal
                  open={inputModal?.length > 0}
                  onClose={() => {
                    setFolderName("");
                    setFolderType("");
                    setError("");
                    setUrl("");
                    setInputModal("");
                  }}
                >
                  <div className="modal-content-input">
                    {(inputModal === "folder" || inputModal === "rename") && (
                      <input
                        type="text"
                        value={folderName}
                        maxLength={50}
                        style={{
                          fontSize: "14px",
                          marginRight: "12px",
                          width: "100%",
                        }}
                        placeholder="Enter folder name"
                        onChange={(e) => {
                          setFolderName(e.target.value);
                          setFolderType("folder");
                        }}
                      />
                    )}
                    {/* Link Name Input */}
                    {(inputModal === "Weblink" ||
                      inputModal === "renameweblink") && (
                      <>
                        {/* Folder Name Input */}
                        <input
                          type="text"
                          value={folderName}
                          maxLength={50}
                          style={{
                            fontSize: "14px",
                            marginRight: "12px",
                            width: "100%",
                            borderColor:
                              error && !folderName?.trim() ? "red" : "", // Red border for validation error
                          }}
                          placeholder="Enter link name"
                          onChange={(e) => {
                            setFolderName(e.target.value);
                            setFolderType("weblink");
                          }}
                        />
                        {error && !folderName?.trim() && (
                          <p
                            style={{
                              color: "red",
                              fontSize: "12px",
                              margin: "0",
                              padding: "2px",
                            }}
                          >
                            Please enter a link name.
                          </p>
                        )}

                        {/* URL Input */}
                        <input
                          type="text"
                          value={url}
                          style={{
                            fontSize: "14px",
                            marginRight: "12px",
                            width: "100%",
                            borderColor:
                              error && (!url || !urlRegex.test(url))
                                ? "red"
                                : "", // Red border for invalid URL
                          }}
                          placeholder="Enter URL"
                          onBlur={handleBlururl}
                          onChange={handleChange}
                        />
                        {error && (!url || !urlRegex.test(url)) && (
                          <p
                            style={{
                              color: "red",
                              fontSize: "12px",
                              margin: "0",
                              padding: "2px",
                            }}
                          >
                            Please enter valid URL format.
                          </p>
                        )}
                      </>
                    )}

                    <button
                      onClick={() => {
                        if (!folderName?.trim()) {
                          setError("Please enter a link name.");
                          return;
                        }
                        if (
                          inputModal === "Weblink" ||
                          inputModal === "renameweblink"
                        ) {
                          if (!url || !urlRegex.test(url)) {
                            setError("Invalid URL format");
                            return;
                          }
                        }
                        // Clear the error if validation passes
                        setError("");

                        // Check conditions based on inputModal value
                        if (inputModal === "folder") {
                          createNewAssetFolder();
                        } else if (
                          inputModal === "renameweblink" ||
                          inputModal === "rename"
                        ) {
                          handleRename();
                        } else if (inputModal === "Weblink") {
                          createNewAssetFolder();
                        }
                      }}
                      style={{ alignSelf: "center" }}
                      className="service-request"
                      disabled={isFolder}
                    >
                      Submit
                    </button>
                  </div>
                </Modal>

                <Modal open={isModalOpen} onClose={handleCloseModal}>
                  <div className="modal-content">
                    <div className="folders-close">
                      <IconButton onClick={() => handleCloseModal()}>
                        <CloseIcon />
                      </IconButton>
                    </div>
                    <div className="modal-header">
                      <h2>Uploaded Files</h2>
                    </div>
                    <div className="modal-header">
                      {!isShared && assetUserRole && (
                        <div className="filesfolders">
                          <button
                            onClick={() => setInputModal("folder")}
                            className="create-folder"
                          >
                            Create Folder
                          </button>

                          <label
                            htmlFor="uploadFile"
                            className="upload-file-btn"
                          >
                            <img src={uploadIcon} width="15px" alt="Add file" />
                            <p>Upload file</p>
                          </label>
                          <button
                            onClick={() => setInputModal("Weblink")}
                            className="create-folder"
                          >
                            Add WebLink
                          </button>
                        </div>
                      )}
                    </div>
                    <div className="top-button-container">
                      <div className="bookmark-asset">
                        {Array.isArray(bookmark) && bookmark.length > 0 && (
                          <div className="breadcrumb">
                            {/* Display the root folder (first item in the array) */}
                            {bookmark
                              .filter((item) => item.parent_unique_id === "0")
                              .map((folder, index) => (
                                <span key={folder?.unique_id}>
                                  {/* Always show the root folder */}
                                  <span
                                    style={{
                                      cursor: "pointer",
                                      padding: "10px",
                                      color: "#446fa2",
                                    }}
                                    onClick={() => {
                                      setSelectedPath(folder?.name); // Set selected path to clicked folder name
                                      setParentUniqueId(folder?.unique_id);
                                    }}
                                  >
                                    {folder?.name}
                                  </span>
                                  {index < bookmark?.length - 1 && " > "}
                                </span>
                              ))}

                            {/* For other folders, show them after the root with ">" separator */}
                            {bookmark
                              .filter((item) => item?.parent_unique_id !== "0")
                              .map((folder) => (
                                <span key={folder.unique_id}>
                                  {" > "}
                                  <span
                                    style={{
                                      cursor: "pointer",
                                      padding: "5px",
                                      color: "#446fa2",
                                    }}
                                    onClick={() => {
                                      setSelectedPath(folder?.name); // Set selected path to clicked folder name
                                      setParentUniqueId(folder?.unique_id);
                                    }}
                                  >
                                    {folder?.name}
                                  </span>
                                </span>
                              ))}
                          </div>
                        )}
                      </div>

                      <input
                        type="file"
                        id="uploadFile"
                        hidden
                        disabled={uploadButtonDisable}
                        onChange={(e) => {
                          const file = e.target.files[0];
                          if (!file) return;
                          const size = file.size;
                          if (isStorageLimitReached(size)) {
                            setShowStorageLimitReachedPopup(true);
                            return;
                          }
                          addAFile(file);
                          e.target.value = "";
                        }}
                        style={{
                          pointerEvents: uploadButtonDisable ? "none" : "auto",
                        }}
                      />
                    </div>
                    <div className="modal-body-files">
                      {Array.isArray(assetFolders) &&
                      assetFolders.length > 0 ? (
                        <div className="asset_single_files">
                          {assetFolders?.map((curr, index) => (
                            <div
                              onClick={() => {
                                curr.type !== "folder"
                                  ? handleDropdownClick(curr?.unique_id)
                                  : handleOpen(curr);
                              }}
                              className="assets-container-files"
                              ref={(el) =>
                                (dropdownRef.current[curr?.unique_id] = el)
                              }
                              key={index}
                            >
                              <div className="assets-container">
                                <div className="dropdown-icon-container">
                                  
                                  {(curr?.type !== "folder" ||
                                    showIFramePopup ||
                                    (assetUserRole && !isShared) ||
                                    confirmPopup ||
                                    curr.type === "file") && (
                                    <img
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleDropdownClick(curr?.unique_id);
                                      }}
                                      src={options}
                                      alt="Menu options"
                                      className="dropdown-icon"
                                    />
                                  )}

                                  {/* Dropdown Menu */}
                                  {dropdownOpenId === curr?.unique_id && (
                                    <div
                                      onClick={(e) => e.stopPropagation()}
                                      className="dropdown-menu dropdown-menu-width"
                                    >
                                      {curr?.type !== "folder" && (
                                        <button
                                          className="btn-dropdown"
                                          onClick={() => {
                                            if (curr?.type === "weblink") {
                                              setShowIFramePopup(true); // Show the iframe popup
                                            } else {
                                              handleOpen(curr); // Handle other types
                                            }
                                          }}
                                        >
                                          Open
                                        </button>
                                      )}

                                      {showIFramePopup && (
                                        <IFramePopup
                                          hide={setShowIFramePopup}
                                          filePath={curr?.path}
                                          org_id={curr?.org_id}
                                        />
                                      )}

                                      {assetUserRole && !isShared && (
                                        <button
                                          className="btn-dropdown"
                                          onClick={() => {
                                            setFolderName(
                                              curr?.name?.includes(".")
                                                ? curr?.name
                                                    .split(".")
                                                    .slice(0, -1)
                                                    .join(".")
                                                : curr?.name
                                            );

                                            setCurrentFolder(curr);
                                            if (curr?.type === "weblink") {
                                              setUrl(curr?.path);
                                              setInputModal("renameweblink");
                                            } else {
                                              setInputModal("rename");
                                            }

                                            setDropdownOpenId(null);
                                          }}
                                        >
                                          Rename
                                        </button>
                                      )}

                                      {assetUserRole && !isShared && (
                                        <button
                                          className="btn-dropdown"
                                          onClick={() => {
                                            setConfirmPopup(true);
                                            setSelectedAssetId(curr);
                                          }}
                                        >
                                          Archive
                                        </button>
                                      )}

                                      {confirmPopup && (
                                        <RenderConfirm
                                          message={`Archive it?`}
                                          data={selectedAssetId}
                                          hide={setConfirmPopup}
                                          status={handleDelete}
                                        />
                                      )}

                                      {curr.type === "file" && (
                                        <button
                                          className="btn-dropdown"
                                          onClick={() => handleDownload(curr)}
                                        >
                                          Download
                                        </button>
                                      )}
                                    </div>
                                  )}
                                </div>

                                <div className="asset-item">
                                  <div className="asset-icon">
                                    {curr.type === "folder" ? (
                                      <img src={bFolder} alt="Folder Icon" />
                                    ) : curr.type === "weblink" ? (
                                      <img
                                        src={webLinkImage}
                                        alt="Folder Icon"
                                      />
                                    ) : (
                                      <img
                                        src={getFileIcon(curr)}
                                        alt="File Icon"
                                      />
                                    )}
                                  </div>
                                  <div
                                    className="asset-name"
                                    role="button"
                                    tabIndex="0"
                                  >
                                    {curr?.name ? curr?.name : null}
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      ) : (
                        <p style={{ padding: "0 30px" }}>No files uploaded.</p>
                      )}
                    </div>
                  </div>
                </Modal>
                <button
                  className="asset-button"
                  style={{ borderRadius: "0 0 10px 0", border: "none" }}
                  onClick={() => {
                    navigate("/service-ticket-user-management", {
                      state: { tab: "assetManagement" },
                    });
                  }}
                >
                  <img src={folderSettings} width="21px" alt="Add Folder" />
                  <p>User Management</p>
                </button>
              </div>
            </div>

            {/* Active Service Tickets Section */}
            <div className="service-tickets-section">
              <div className="service-tickets-header">
                <div className="header-one">
                  {/* Button to show service requests section */}
                  <button
                    className={` ${
                      activeSection === "serviceRequests"
                        ? "active header-one-btn-one"
                        : "header-one-btn-two"
                    }`}
                    onClick={() => setActiveSection("serviceRequests")}
                  >
                    SERVICE REQUESTS
                  </button>

                  {/* Button to show parts management section */}
                  <button
                    className={`${
                      activeSection === "partsManagement"
                        ? "active header-one-btn-one"
                        : "header-one-btn-two"
                    }`}
                    onClick={() => setActiveSection("partsManagement")}
                  >
                    PARTS MANAGEMENT
                  </button>
                </div>

                {/* Conditionally render the header based on active section */}
                {activeSection === "serviceRequests" ? (
                  <div className="header-two">
                    <h2>Active Service Tickets</h2>
                    {!isShared && userData && (
                      <button
                        className="header-one-btn-one"
                        onClick={() => {
                          dispatch(assetuniqueid(uniqueid));
                          dispatch(assethashid(editedAsset?.assetNumber));
                          navigate("/asset-service-ticket-create", {
                            state: {
                              ticketType: "new ticket",
                            },
                          });
                        }}
                      >
                        CREATE NEW TICKET
                      </button>
                    )}
                  </div>
                ) : (
                  <div className="header-two">
                    <h2>Parts for Asset</h2>
                    {!isShared && assetUserRole && (
                      <button
                        className="header-one-btn-one "
                        style={{ padding: "8px 40px" }}
                        onClick={() => {
                          dispatch(assetuniqueid(uniqueid));
                          dispatch(assethashid(editedAsset?.assetNumber));
                          navigate("/add-asset-part");
                        }}
                      >
                        ADD PART
                      </button>
                    )}
                  </div>
                )}
              </div>

              {/* Conditionally render the content based on active section */}
              {activeSection === "serviceRequests" ? (
                <div>
                  <div className="service-search-filter">
                    <div className="asset-search">
                      <input
                        type="text"
                        placeholder="Search tickets by ticket # or requestor name"
                        onChange={(e) => {
                          setSearchKeyword(e.target.value);
                          setFiltering(e.target.value);
                        }}
                      />
                      <SearchIcon className="search-icon" />
                    </div>
                    <div className="asset-filter">
                      <label>Filter:</label>
                      <select
                        value={filterKeyword}
                        onChange={(e) => {
                          setFilterKeyword(e.target.value);
                          setFiltering(e.target.value);
                        }}
                      >
                        <option value="all">All</option>
                        <option value="urgent">Urgent</option>
                        <option value="moderate">Moderate</option>
                        <option value="low">Low</option>
                        <option value="Closed">Closed</option>
                        <option value="Pause">Paused</option>
                      </select>
                    </div>
                  </div>
                  <div className="service-ticket-table">
                    <div className="service-ticket-label">
                      <div className="asset-head-row">TICKET#</div>
                      <div className="asset-head-row">REQUESTOR</div>
                      <div className="asset-head-row">ASSIGNED TO</div>
                      <div className="asset-head-row">SEVERITY</div>
                      <div className="asset-head-row">OPEN DATE</div>
                    </div>
                    {assetServiceTicket?.length > 0 ? (
                      <div>
                        {assetServiceTicket.map((ticket, index) => (
                          <div key={index} className="service-ticket-content">
                            <div
                              className="asset-row"
                              onClick={() => {
                                dispatch(ticketIdAction(ticket?.ticket_id));
                                // navigate("/service-ticket-details");
                                navigate("/service-ticket-details");
                              }}
                              style={{ color: "#436EA0", fontWeight: 700 }}
                            >
                              {ticket?.ticket_id}
                            </div>
                            <div className="asset-row">
                            {ticket?.requestor &&
                                ticket?.requestor.length > 18
                                  ? `${ticket?.requestor.slice(0, 18)}...`
                                  : ticket?.requestor || ticket?.requestor_org_name}
                              {/* {ticket?.requestor || ticket?.requestor_org_name} */}
                            </div>
                            <div className="asset-row">
                              {ticket?.assign_tech || "N/A"}
                            </div>
                            <div className="asset-row severity">
                              {ticket?.severity_issue.toString() ===
                              "urgent" ? (
                                <>
                                  <img src={Urgent} alt="Urgent" /> Urgent
                                </>
                              ) : ticket?.severity_issue.toString() ===
                                "moderate" ? (
                                <>
                                  <img src={Moderate} alt="Moderate" /> Moderate
                                </>
                              ) : ticket?.severity_issue.toString() ===
                                "low" ? (
                                <>
                                  <img src={Low} alt="Low" /> Low
                                </>
                              ) : ticket?.severity_issue.toString() ===
                                "pause" ? (
                                <>
                                  <img src={Pause} alt="Pause" /> Paused
                                </>
                              ) : ticket?.severity_issue.toString() ===
                                "closed" ? (
                                <>
                                  <span
                                    style={{
                                      backgroundColor: "orange",
                                      borderRadius: "2px",
                                      textAlign: "center",
                                      width: "20px",
                                      height: "18px",
                                      color: "white",
                                      marginRight: "5px",
                                    }}
                                  >
                                    C
                                  </span>
                                  Closed
                                </>
                              ) : null}
                            </div>
                            <div className="asset-row">
                              {new Date(ticket?.createdAt).toLocaleDateString()}
                            </div>
                          </div>
                        ))}
                      </div>
                    ) : (
                      <div className="service-ticket-content">
                        <p className="no-ticket">
                          No Ticket Exist for asset {editedAsset?.assetNumber}
                        </p>
                      </div>
                    )}
                  </div>
                </div>
              ) : (
                <div>
                  <div className="service-search-filter">
                    <div className="asset-search">
                      <input
                        type="text"
                        placeholder="Search part by parts# "
                        onChange={(e) => {
                          setpartSearchKeyword(e.target.value);
                          setpartSearchKeyword(e.target.value);
                        }}
                      />
                      <SearchIcon className="search-icon" />
                    </div>
                  </div>
                  <div className="service-ticket-table">
                    <div className="service-ticket-label">
                      <div className=" parts">PART#</div>
                      <div className=" parts-description">DESCRIPTION</div>
                      <div className=" parts">QUANTITY ON HAND</div>
                    </div>
                    {assetPart && assetPart.length > 0 ? (
                      <div>
                        {filteredPart
                          .sort(
                            (a, b) =>
                              new Date(b.createdAt) - new Date(a.createdAt)
                          ) // Sort by creation date, descending order
                          .map((ticket, index) => (
                            <div key={index} className="service-ticket-content">
                              <div
                                className=" part-content"
                                onClick={() => {
                                  dispatch(ticketIdAction(ticket?.unique_id));
                                  navigate("/part-detail", {
                                    state: { shared: isShared },
                                  });
                                }}
                                style={{ color: "#436EA0", fontWeight: 700 }}
                              >
                                {ticket?.partHash &&
                                ticket?.partHash.length > 20
                                  ? `${ticket?.partHash.slice(0, 20)}...`
                                  : ticket?.partHash || "N/A"}
                              </div>
                              <div className=" part-description">
                                {ticket?.description &&
                                ticket?.description.length > 20
                                  ? `${ticket?.description.slice(0, 30)}...`
                                  : ticket?.description || "N/A"}
                              </div>

                              <div className="part-content">
                                {ticket?.inventoryLocation &&
                                ticket?.inventoryLocation.length > 0
                                  ? ticket?.inventoryLocation.reduce(
                                      (total, item) =>
                                        total + parseInt(item?.quantity, 10),
                                      0
                                    )
                                  : "N/A"}
                              </div>
                            </div>
                          ))}
                      </div>
                    ) : (
                      <div className="service-ticket-content">
                        <p className="no-ticket">
                          No Part Exist for asset {editedAsset?.assetNumber}
                        </p>
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {internalRequestPopup ? (
        <ServiceRequestForm
          hide={() => setInternalRequestPopup(false)} // Function to hide the form
          service_org_id={myAsset?.organizationDetail.id}
          assetRequesttype={!isShared ? "internal" : "external"}
          oldAssetNumber={!isShared ? editedAsset.assetNumber : editedAsset.oldAssetNumber}
        />
      ) : null}
      {showPopup && (
        <div className="popupStyle">
          <div className="messagestyling">
            <p>You don't have access to this asset</p>
          </div>
        </div>
      )}

      {loader}
    </>
  );
};

export default AssetDetails;
