import LoginUI from "../PureComponents/LoginUI";
import React from "react";
import {
  handleDeepLinking,
  handleDeepLinkingasset,
  handleDeepLinkingassetpart,
} from "Constants/Functions";
import { logging } from "Redux/action/auth.action";
import { login } from "Config/API/authentication";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

const RenderLoginUI = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // this is our main verification method
  const verifyUser = (email, password) => {
    const login_status = login(email, password);
    login_status
      .then((res) => {
        // dispatching the login
        dispatch(logging(res?.info));
        // storing token in localstorage
        localStorage.setItem("token", res?.token);

        // reset password token
        const resetToken = res?.info?.reset ? res?.info.reset : "";

        // dispatching alerts
        if (res?.status) {
          // check reset page
          if (resetToken?.toString() === "1") {
            navigate("reset-password");
          } else {
            const from = localStorage.getItem("from");
            if (from) {
              if (from?.includes("document/")) {
                handleDeepLinking(from, navigate, dispatch, res?.token);
              } else if (from?.includes("portfolio")) {
                window.location.replace(from);
              } else if (from?.includes("asset/")) {
                handleDeepLinkingasset(from, navigate, dispatch);
              } else if (from?.includes("assetPart/")) {
                handleDeepLinkingassetpart(from, navigate, dispatch);
              }
            } else {
              // normal login
              navigate("/dashboard");
            }
          }
        } else {
          toast(res?.message, {
            type: "error",
          });
        }
      })
      .catch(() =>
        toast("Failed! Try again later", {
          type: "error",
        })
      );
  };

  return (
    <>
      <LoginUI verify={verifyUser} />
    </>
  );
};

export default RenderLoginUI;
