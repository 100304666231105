
import React, { useRef } from "react";
import { Button } from "@mui/material";
import QRcode from "Components/FileExplorer/QRcode/QRcode";
import amniusLogo from "Media/Amnius_connect_with_text.png";

import useFullPageLoader from "Hooks/useFullPageLoader";
import { qrcodeImageRequest } from "Config/API/action";

const AssetQrPopup = ({ orglogo,  assetName, partName,hide, path,demologo }) => {
  const token = localStorage.getItem("token");
  const componentRef = useRef();
  const [loader, setLoader, resetLoader] = useFullPageLoader();

  const downloadBase64Image = (base64Image) => {
    try {
      const link = document.createElement("a");
      link.href = base64Image;
      link.setAttribute("download", "qr-code.png");
      document.body.appendChild(link);
      link.click();
      // Clean up and remove the link
      document.body.removeChild(link);
      console.log("Base64 image downloaded successfully!");
    } catch (error) {
      console.error("Error downloading the base64 image:", error);
    }
  };
const emptylogo="";
let connected = partName ? "Part Owner" : "Asset Owner";

  const generateQrCode = async (logo1, logo2) => {
    const qrcode_request = qrcodeImageRequest(
      token,
      path,
      orglogo,
      emptylogo,
      connected,
      partName ? partName : assetName 
    );
    
    try {
      const res = await qrcode_request;
      console.log("QR Code generation response:", res);
      if (res && res?.base64Image) {
        downloadBase64Image(res?.base64Image);
      
      } else {
        console.error("Invalid response or base64 image data is missing");
      }
    } catch (error) {
      console.error("Error generating QR code:", error);
    }
  };
  const renderImage = () => (
    <div style={{ display: "flex", gap: "8px", marginTop: "15px" }}>
      <img src={demologo} alt="organisation logo" />
   
    </div>
  );


  const renderHeader = () => {
    console.log(path)
      return (
        <>
          <div className="qr-title" style={{color:"black"}}> {partName ? partName : assetName}</div>
          <div
            style={{ fontWeight: "bold", textTransform: "uppercase",color:"black" ,fontSize:"15px"}}
            className="qr-code-connected-folder"
          >
        {partName ? "Part Owner" : "Asset Owner"}
          </div>
        </>
      );
  };
  return (
    <>
      <nav className="qr-container">
        <div className="qr-popup">
          <div className="qr-cross" onClick={() => hide(false)}>
            X
          </div>
          <div className="qr-body" ref={componentRef}>
            <QRcode path={path} />
            {renderHeader()}
            {renderImage()}
            <div className="qr-row">
              <div className="qr-title" style={{color:"black"}}>POWERED BY:</div>
              <div style={{ display: "flex", alignItems: "center" }}>
                <img src={amniusLogo} alt="" className="amnius-logo" />
              </div>
            </div>
          </div>

          <div className="qr-print-btn">
            <Button
              variant="contained"
              style={{ background: "#446FA2" }}
              onClick={generateQrCode}
            >
              DOWNLOAD
            </Button>
          </div>
        </div>
        {loader}
      </nav>
    </>
  );
};

export default AssetQrPopup;
