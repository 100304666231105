import React, { useEffect, useRef, useState } from "react";
import {
  getAssetAllFolder,
  getCategoryData,
  getLocationData,
} from "Config/API/get";
import {
  raiseRequestFileUpload,
  raiseServiceRequestApi,
} from "Config/API/action";
import "./ServiceRequestForm.css";
import ExternalIcon from "Media/icons/External_icon.svg";
import StorageLimitReachedPopup from "Components/Popup/StorageLimitReachedPopup/StorageLimitReachedPopup";
import folderIcon from "Media/icons/Folder-Close.png";
import { toast } from "react-toastify";
import useFullPageLoader from "Hooks/useFullPageLoader";
import useIsStorageLimitReached from "Hooks/useIsStorageLimitReached";
import { useSelector } from "react-redux";

const ServiceRequestForm = ({
  hide,
  service_org_id,
  ticketData,

  assetRequesttype,
  oldAssetNumber,
}) => {
  const token = localStorage.getItem("token");
  const general_info = useSelector((state) => state.authenticationReducer);
  const organization_name = useSelector((state) => state.renderGeneralInfo);
  const isStorageLimitReached = useIsStorageLimitReached();
  const assetId = useSelector((state) => state.assetIdReducer);
  const assethash = useSelector((state) => state.assetHashReducer);
  // fields to fill
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [requestorOrganization, setRequestorOrganization] = useState(
    organization_name?.organization_name || ""
  );
  const [requestorName, setRequestorName] = useState(general_info?.name || "");
  const [requestorEmail, setRequestorEmail] = useState(
    general_info?.email || ""
  );
  const [subject, setSubject] = useState("");
  const [description, setDescription] = useState("");
  const [folderList, setFolderList] = useState([]);
  const [folder, setFolder] = useState("" || ticketData?.folderNameState);
  const [folderLink, setFolderLink] = useState(
    "" || ticketData?.folderLinkState
  );
  const [folderId, setFolderId] = useState("" || ticketData?.folderIdState);
  const [location_list, setLocation_list] = useState([]);
  const [location, setLocation] = useState("");
  const [teams, setTeams] = useState([]);
  const [category_id, setCategory_id] = useState("");
  const [files, setFiles] = useState([]);
  // to show popups
  const [showStorageLimitReachedPopup, setShowStorageLimitReachedPopup] =
    useState(false);
  const [loader, setLoader, resetLoader] = useFullPageLoader();
  const folderRef = useRef(null);
  useOutsideAlerter(folderRef);

  function useOutsideAlerter(ref) {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          ref.current.classList.add("hide-dropdown");
        } else {
          if (ref.current !== null) {
            ref.current.classList.remove("hide-dropdown");
          }
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    var folder_name = folderId;
    setIsSubmitting(true);
    if (folderId?.toString() === "" && folderLink?.toString() === "") {
      folder_name = folder;
    }
    setLoader();
    const raise_request_status = raiseServiceRequestApi(
      token,
      service_org_id,
      "",
      description,
      subject,
      folderLink,
      folder_name,
      location,
      category_id,
      assetRequesttype,
      oldAssetNumber,
      assetId
    );

    raise_request_status.then((res) => {
      if (res?.data?.status) {
        if (files?.length > 0) {
          const request_service_files = raiseRequestFileUpload(
            token,
            res?.data?.id,
            files
          );
          request_service_files.then((ress) => {
            resetLoader();
            if (ress?.status) {
              hide(false);
              setIsSubmitting(false);
              toast(res?.message, { type: "success" });
            } else {
              toast(ress?.message, { type: "error" });
              setIsSubmitting(false);
            }
          });
        } else {
          hide(false);
          setIsSubmitting(false);
          resetLoader();
          toast(res?.message, { type: "success" });
        }
      } else {
        setIsSubmitting(false);
        resetLoader();
        toast(res?.message, { type: "error" });
      }
    });
  };

  // firstly getting ticket id
  useEffect(() => {
    getAllLocations();
    console.log(assetRequesttype);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const getAllLocations = () => {
    const locationList = getLocationData(token, general_info?.id);
    locationList.then((res) => {
      if (res?.location?.length > 0) {
        setLocation_list(res?.location);
      }
    });
  };

  useEffect(() => {
    console.log(location);
    console.log(general_info);
    console.log(token);
    let org_id;

    if (general_info?.role === "admin") {
      org_id = general_info?.id;
    } else if (general_info?.role === "employee") {
      org_id = general_info?.org_id;
    }

    const categoryList = getCategoryData(token, org_id, location);
    categoryList.then((res) => {
      setTeams(res?.category);
    });
  }, [location]);

  return (
    <>
      <div className="straisereq-parent">
        <div className="straisereq-main">
          <form
            className="straisereq-form-body"
            onSubmit={handleSubmit}
            autoComplete="off"
          >
            <div className="straisereq-heading">
              <p>Asset Service Request Form</p>
              <div className="rename-cross" onClick={() => hide(false)}>
                X
              </div>
            </div>
            <div className="straisereq-wrapper">
              <div className="straisereq-fields">
                <label htmlFor="Asset">
                  Asset #<span>*</span>:
                </label>

                <input
                  style={{ backgroundColor: "#f1f2f6" }}
                  type="text"
                  id="Asset#"
                  value={assethash}
                  readOnly
                  required
                />
              </div>
              {requestorOrganization?.toString() !== "" ? (
                <div className="straisereq-fields">
                  <label htmlFor="organization">
                    Requestor Organization<span>*</span>:
                  </label>

                  <input
                    style={{ backgroundColor: "#f1f2f6" }}
                    type="text"
                    id="organization"
                    value={requestorOrganization}
                    readOnly
                    required
                  />
                </div>
              ) : (
                <div className="straisereq-fields">
                  <label htmlFor="organization">
                    Requestor Organization<span>*</span>:
                  </label>

                  <input
                    type="text"
                    id="organization"
                    value={requestorOrganization}
                    onChange={(e) => {
                      setRequestorOrganization(e.target.value);
                    }}
                    required
                  />
                </div>
              )}

              <div className="straisereq-fields">
                <label htmlFor="contact-name">
                  Requestor Name<span>*</span>:
                </label>
                <input
                  style={{ backgroundColor: "#f1f2f6" }}
                  type="text"
                  value={requestorName}
                  id="contact-name"
                  onChange={(e) => {
                    setRequestorName(e.target.value);
                  }}
                  readOnly
                  required
                />
              </div>

              <div className="straisereq-fields">
                <label htmlFor="contact-email">
                  Requestor Email<span>*</span>:
                </label>
                <input
                  style={{ backgroundColor: "#f1f2f6" }}
                  type="text"
                  value={requestorEmail}
                  id="contact-email"
                  onChange={(e) => {
                    setRequestorEmail(e.target.value);
                  }}
                  readOnly
                  required
                />
              </div>

              <div className="straisereq-fields">
                <label htmlFor="description">
                  Subject<span>*</span>:
                </label>
                <input
                  type="text"
                  id="subject"
                  placeholder="Enter Issue Title"
                  onChange={(e) => {
                    setSubject(e.target.value);
                  }}
                  required
                />
              </div>

              <div className="stcreate-fields stcreate-file-upload">
                <label htmlFor="file">Upload Files:</label>
                <input
                  type="file"
                  id="file"
                  multiple
                  accept=".png,.jpg,.jpeg,.pdf, .docx, .doc, .xlsx, .xls, .csv, .mp4"
                  onChange={(e) => {
                    let totalSize = 0;
                    for (let i = 0; i < e.target?.files?.length; i++) {
                      totalSize = totalSize + e.target.files[i].size;
                    }
                    if (isStorageLimitReached(totalSize))
                      return setShowStorageLimitReachedPopup(true);
                    // const filesArray = [];
                    // filesArray.push(e.target.files);
                    setFiles(e.target.files);
                  }}
                />
              </div>
              {assetRequesttype === "internal" && (
                <>
                  <div className="stcreate-fields">
                    <label htmlFor="location">
                      Select Location<span>*</span>:
                    </label>
                    <select
                      required
                      className="severity-wrapper"
                      onChange={(e) => setLocation(e.target.value)}
                      name="location"
                      defaultValue="" // defaultValue helps avoid `selected` in the option directly
                    >
                      <option value="" disabled>
                        -- Select an option --
                      </option>
                      {location_list?.map((item) => (
                        <option key={item?.id} value={item?.id}>
                          {item?.location}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div className="stcreate-fields">
                    <label htmlFor="team">
                      Select Team<span>*</span>:
                    </label>
                    <select
                      required
                      id="team"
                      name="team"
                      className="severity-wrapper"
                      onChange={(e) => setCategory_id(e.target.value)}
                      disabled={!location}
                      defaultValue="" // Use defaultValue to manage initial selection
                    >
                      <option value="" disabled>
                        -- Select an option --
                      </option>
                      {teams?.length > 0 ? (
                        teams.map((item) => (
                          <option key={item?.id} value={item?.id}>
                            {item?.category_id}
                          </option>
                        ))
                      ) : (
                        <option disabled>Add team first</option>
                      )}
                    </select>
                  </div>
                </>
              )}

              <div className="straisereq-fields">
                <label htmlFor="description">
                  Description<span>*</span>:
                </label>
                <textarea
                  id="description"
                  rows="5"
                  placeholder="Enter Issue Description"
                  onChange={(e) => {
                    setDescription(e.target.value);
                  }}
                  name="description"
                  required
                ></textarea>
              </div>

              <div className="straisereq-fields">
                <button type="submit" disabled={isSubmitting}>
                  Raise Request
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
      {showStorageLimitReachedPopup && (
        <StorageLimitReachedPopup hide={setShowStorageLimitReachedPopup} />
      )}
      {loader}
    </>
  );
};

export default ServiceRequestForm;
