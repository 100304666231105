import React, { useState, useRef, useEffect } from "react";
import "./ShareAssetPopup.css";
import { getOrganizationConnection, sharedAssetRequest } from "Config/API/get";
import { toast } from "react-toastify";
import demo from "Media/blank-profile-picture.png";
const ShareAssetPopup = ({ hide ,assetId}) => {
  const token = localStorage.getItem("token");
  const [orgName, setOrgName] = useState('');
  const [org, setOrg] = useState([]); // Store the array of organizations directly
  const [showOrg, setShowOrg] = useState(false);
  const shareassetRef = useRef(null);
const [connId,setConnId]=useState('')
const handleInputChange = async (e) => {
  const value = e.target.value;
  setOrgName(value); 
  setShowOrg(true);  

  try {
    const orgData = await getOrganizationConnection(token, "");

    const filteredOrgs = (orgData?.organizationData || []).filter((org) =>
      org.organization_name.toLowerCase().includes(value.toLowerCase()) 
    );


    setOrg(filteredOrgs); 
  } catch (error) {
    console.error("Error fetching organizations:", error);
  }
};

  // Show dropdown on input field click if empty
  const handleInputClick = async () => {
    if (!orgName.length) {
      const orgData = await getOrganizationConnection(token, "");
      setOrg(orgData?.organizationData || []); // Use fallback array if undefined
    }
    setShowOrg(true);
  };

  // Hide dropdown if user clicks outside the dropdown or input field
  const handleOutsideClick = (event) => {
    if (shareassetRef.current && !shareassetRef.current.contains(event.target)) {
      setShowOrg(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);
    return () => document.removeEventListener("mousedown", handleOutsideClick);
  }, []);

  const handleSubmit = async () => {
    if (!orgName) {
        toast.error("Please enter an organization name.");
        return; // Exit early if orgName is empty
      }
    try {
      const sharedRequest = await sharedAssetRequest(token, assetId,connId);
      
      if (sharedRequest.status === true) {
        toast.success(sharedRequest.message);
        hide(false)
      } else {
        toast.error(sharedRequest.message);
      }
    } catch (error) {
      toast.error("An error occurred. Please try again.");
      console.error("Error in handleSubmit:", error);
    }
  };
  return (
    <div className="share-asset-overlay">
      <div className="share-asset-container">
        <div className="share-asset-header">
          <span>Share Asset</span>
          <button className="close-asset-sharing-popup" onClick={() => hide(false)}>
            X
          </button>
        </div>

        <div className="share-asset-dropdown">
          <label>Select Organization</label>
          <div className="sharing-list" ref={shareassetRef}>
            <input
              type="text"
              placeholder="Enter organization name"
              value={orgName}
              onChange={handleInputChange}
              onClick={handleInputClick}
              required
            />
            {showOrg && (
              <div className="organization-list asset-list">
                {org.length > 0 ? (
                  org.map((vendor, index) => (
                    <div
                      className="data-list"
                      key={index}
                      onClick={() => {
                        setOrgName(vendor.organization_name);
                        setConnId(vendor.id)
                        setShowOrg(false); 
                      }}
                    >
                      <div className="organization-img-parent">
                        <img src={(vendor.logo)?(vendor.logo):demo} alt="Vendor" />
                      </div>
                      <span className="multiple-line-text">
                        {vendor.organization_name || "No Name"}
                      </span>
                    </div>
                  ))
                ) : (
       null
                )}
              </div>
            )}
          </div>
          <button className="share-btn" onClick={handleSubmit}>Share</button>
        </div>
      </div>
    </div>
  );
};

export default ShareAssetPopup;
